import React, { useState, useEffect } from 'react';
import './App.css';
import './carrusel.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import logoSIT from './img/logo_pipal.png';
import footer from './img/logo_footer.png';

import netbook from './img/netbook.svg';
import precauciones from './img/precauciones3.svg';
import manual from './img/manual3.svg';
import logoMesa from './img/lista2.svg';
import escuela from './img/escuela.svg';


import bot from './img/botsi.png';


function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [isDancing, setIsDancing] = useState(false);
    const [escuelaNombre, setEscuelaNombre] = useState(''); // Estado para el nombre de la escuela
    const [resultados, setResultados] = useState(null); // Estado para los resultados de la búsqueda
    const [loading, setLoading] = useState(false); // Estado para controlar si está cargando
    const [error, setError] = useState(null); // Estado para controlar el error

    const videoFiestas = "https://www.youtube.com/embed/BJ7709gS-3M?si=cNbK60r4GD-PxTR0";

    const resetVariables = () => {
      setIsOpen(false);
      setIsDancing(false);
      setResultados(null);
      setLoading(false);
      setError(null);
      setFiltro('');
  };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setIsDancing((prev) => !prev);
        }, 5000); // Cambiar estado cada 5 segundos

        return () => clearInterval(interval); // Limpiar intervalo al desmontar
    }, []);

    // Función para manejar el cambio en el input
    const handleInputChange = (e) => {
        setEscuelaNombre(e.target.value);
    };

    // Función para hacer la solicitud al API
    const buscarEscuela = async (page = 1) => {


        if (!escuelaNombre) {
            alert('Por favor ingresa el nombre de la escuela');
            return;
        }

        resetVariables();
        setLoading(true);
        setError(null);

        const objeto = {
            cantPag: 25,
            escuela_nombre: escuelaNombre
        };

        const objetoCodificado = encodeURIComponent(JSON.stringify(objeto));

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        try {
            const response = await fetch(`https://escuelas.educacioncba.edu.ar/api/2/escuela/test-json?json=${objetoCodificado}&page=${page}`, options);
            const data = await response.json();
            setResultados(data); 

        } catch (err) {
            setError('Error al hacer la solicitud');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    const categories = [
        {
            category: "Desbloqueá tu netbook",
            image: netbook,
            description: "Accede a la herramienta para desbloquear tu netbook.",
            link: "https://desbloqueos.educacioncba.edu.ar",
        },
        {
            category: "Asistencia Técnica",
            image: logoMesa,
            description: "Gestiona las asistencias técnicas para tu institución.",
            link: "https://mesadeayuda.educacioncba.edu.ar",
        },
        {
            category: "Recomendaciones de Uso",
            image: precauciones,
            description: "Conoce las recomendaciones para un uso seguro.",
            link: "https://educacioncba.edu.ar/recomendaciones/",
        },
        {
            category: "Manuales",
            image: manual,
            description: "Consulta los manuales disponibles para aprender más.",
            link: "https://educacioncba.edu.ar/manual-carros/",
        },
        
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
        }, 10000);
    
        return () => clearInterval(interval); 
    }, []);
    
    const handlePrev = () => {
        setCurrentIndex(
          (prevIndex) => (prevIndex - 1 + categories.length) % categories.length
        );
    };
    
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
    };

    const [filtro, setFiltro] = useState('');

    //  manejar el cambio en el input de búsqueda
    const handleFiltroChange = (event) => {
      setFiltro(event.target.value);
    };
  
  //  eliminar los acentos de una cadena
  const quitarAcentos = (texto) => {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  // Filtrar los resultados con base en el filtro
  const resultadosFiltrados = resultados?.data?.filter((escuela) => {
    const filtroSinAcentos = quitarAcentos(filtro.toLowerCase());
  
    // Verifica si escuela.ubicacion existe antes de acceder a localidad y departamento
    const nombreCoincide = quitarAcentos(escuela.nombre.toLowerCase()).includes(filtroSinAcentos);
    const localidadCoincide = escuela.ubicacion?.localidad 
      ? quitarAcentos(escuela.ubicacion.localidad.toLowerCase()).includes(filtroSinAcentos) 
      : false;
    const departamentoCoincide = escuela.ubicacion?.departamento 
      ? quitarAcentos(escuela.ubicacion.departamento.toLowerCase()).includes(filtroSinAcentos) 
      : false;


    return nombreCoincide || localidadCoincide || departamentoCoincide ;
  });


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      buscarEscuela();
    }
  };

    return (
        <div>
            <nav className="navbar navbar-light mb-4 ">
                <div className="container-fluid ">  
                    <div className="col-12 ">
                        <a className="navbar-brand d-flex justify-content-center" href="#" onClick={() => window.location.reload()} >
                            <img src={logoSIT} className="d-inline-block align-text-center brand-logo" />
                        </a>
                        <div class="col-12 d-flex justify-content-center" id="cabecera"></div>
                    </div>
                </div>
            </nav>

            <div className="container mb-4">
                {/* Slider */}
                <div className="slider-container">
                    <div className="slider">
                        <div className="slider-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                            {categories.map((item, index) => (
                                <div key={index} className="slider-item">
                                    <div className="category-info">                                       
                                        <img src={item.image} alt={item.category} />
                                    </div>
                                    <div className="category-details">
                                        <h5>{item.category}</h5>
                                        <p>{item.description}</p>                                        
                                        <a href={item.link} className='btn btn-outline-secondary' target="_blank" rel="noopener noreferrer">
                                 
                                            Ver más información
                                   
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="slider-nav">
                            <button className="prev" onClick={handlePrev}>
                                &#8249;
                            </button>
                            <button className="next" onClick={handleNext}>
                                &#8250;
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card mt-2 d-none">
                <div className="row mt-4 d-flex justify-content-center">
                  <div className="col-12 col-md-8 mt-2 mb-2">
                     
                          <div className="video-container">
                          <iframe 
                              src={videoFiestas} 
                              title="YouTube video player" 
                              frameborder="0" 
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                              referrerpolicy="strict-origin-when-cross-origin" 
                              allowfullscreen>                
                            </iframe>
                          </div>
                      </div>
                  </div>
              </div>
              
                {/* Categorías */}
                <div className="row mt-4 d-flex justify-content-center">
                    {categories.map((item, index) => (
                        <div key={index} className="col-12 col-lg-3 mt-4">
                            <a href={item.link} className="category-card card">
                                <img src={item.image} className="img-fluid category-image" alt={item.category} />
                                <div className="category-card-body text-secondary">
                                    <h5>{item.category}</h5>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                <div className="row mt-4">
                    <div className="card mt-4">
                        <div className="card-body row">
                            <div className="col-12 col-md-4 mb-4">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <img src={escuela} className="img-fluid buscar-image" alt="Category Image" />
                                    </div>
                                    <div className="col-12">
                                        <h5 className="text-center text-secondary">Buscador de escuelas</h5>
                                        <div className="card-body">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={escuelaNombre}
                                                onChange={handleInputChange}
                                                aria-label="Nombre de la escuela"
                                                placeholder="Ingresa el nombre de la escuela"
                                                id="btn-buscar"
                                                onKeyDown={handleKeyDown} 
                                            />
                                            <button className="btn btn-outline-secondary" type="button" onClick={buscarEscuela}>
                                                Buscar
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="col-12 col-md-8 d-none">
                                {/* Mostrar resultados o mensaje de error */}
                                {loading && <p>Cargando...</p>}
                                {error && <p>{error}</p>}
                                
                                {resultados && (
                                    <div>
                                        <h5 className='text-secondary'>Resultados</h5>
                                        <pre>{JSON.stringify(resultados, null, 2)}</pre> {/* Mostrar resultados de búsqueda */}
                                    </div>
                                )}


               
                            </div>
                            <div className="col-12 col-md-8">
                                {/* Mostrar resultados o mensaje de error */}
                                {loading && <p>Cargando...</p>}
                                {error && <p>{error}</p>}
                                {resultadosFiltrados ? (
                                  <div>
                                     <h4 className='text-secondary mb-3 mt-3'>Resultados</h4>

                                    <div className="input-group mb-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Ingrese localidad o departamento a filtrar"
                                        value={filtro}
                                        onChange={handleFiltroChange}
                                      />
                                      <button className="btn btn-outline-secondary" type="button" onClick={buscarEscuela}>
                                        Buscar
                                      </button>
                                    </div>
                                    <p className='text-secondary'>Coincidencias: {resultadosFiltrados.length}</p>
                                  </div>
                                  ) : (
                                      ''
                                  )}
                                {resultadosFiltrados ? (
                                   resultadosFiltrados.length > 0 ? (
                                    <div>
                                     
                                            {/* Campo de búsqueda */}

                                        {resultadosFiltrados.map((escuela, index) => (
                                          <div className="card mb-3 mt-4" key={index}>
                                            <div className="row">
                                              <div className="col-12 col-md-3 mx-3 mt-2">
                                                <div className="card-body">
                                                  <h5>{escuela.cue}</h5>
                                                  <p className="card-text">{escuela.nombre}</p>
                                                  <p className="card-text">{escuela.nivel}</p>
                                                </div>
                                              </div>
                                              <div className="col-12 col-md-8 mb-3 mt-3">
                                                <ul className="list-group list-group-flush">
                                                  <li className="list-group-item">
                                                    <div className="row">
                                                      <div className="col-12 col-md-6">
                                                        <div className="input-group mb-2 mt-2">
                                                          <span className="input-group-text">Localidad</span>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled
                                                            value={escuela.ubicacion === null ? '' : escuela.ubicacion.localidad}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-md-6">
                                                        <div className="input-group mb-2 mt-2">
                                                          <span className="input-group-text">Departamento</span>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled
                                                            value={escuela.ubicacion === null ? '' : escuela.ubicacion.departamento}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li className="list-group-item">
                                                    <div className="row">
                                                      <div className="col-12 col-md-6">
                                                        <div className="input-group mb-2 mt-2">
                                                          <span className="input-group-text">Dirección</span>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled
                                                            value={escuela.ubicacion === null ? '' : escuela.ubicacion.direccion}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                  <li className="list-group-item">
                                                    <div className="row">
                                                      <div className="col-4">
                                                        <div className="input-group mb-2 mt-2">
                                                          <span className="input-group-text">LAT</span>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled
                                                            value={escuela.ubicacion === null ? '' : escuela.ubicacion.latitud}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-4">
                                                        <div className="input-group mb-2 mt-2">
                                                          <span className="input-group-text">LONG</span>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled
                                                            value={escuela.ubicacion === null ? '' : escuela.ubicacion.longitud}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-3 mt-3">

                                                        <a
                                                          className="mx-2"
                                                          href={`https://maps.google.com/maps?z=18&q=${ escuela.ubicacion ? `${escuela.ubicacion.latitud},${escuela.ubicacion.longitud}` : null}`}
                                                          target="_blank"
                                                          title={escuela.ubicacion ? `Ubicación en el mapa: Latitud ${escuela.ubicacion.latitud}, Longitud ${escuela.ubicacion.longitud}` : 'Ubicación no disponible'}
                                                        >
                                                          <span className="material-icons">pin_drop</span>
                                                        </a>
                                                        <a
                                                          href={`https://maps.google.com/maps?z=18&q=${ escuela.ubicacion ? `${escuela.ubicacion.direccion}, ${escuela.ubicacion.localidad}` : null}`}
                                                          target="_blank"
                                                          title={escuela.ubicacion ? `Dirección: ${escuela.ubicacion.direccion}, Localidad: ${escuela.ubicacion.localidad}` : 'Dirección no disponible'}
                                                        >
                                                          <span className="material-icons">my_location</span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      
                                    </div>
                                  ) : (
                                    // Muestra este mensaje si el array está vacío
                                    <p>No se encontraron resultados.</p>
                                )
                            ) : null}
                                </div>


                        </div>
                    </div>
                </div>

            </div>




            {/* Botón flotante y popup */}
            <div className="row mt-3">
                <div className="col-12">
                    <button className={`custom-button floating-button ${isDancing ? 'bailar' : ''}`} onClick={togglePopup}>
                        <div className="button-content">
                            <img src={bot} className="custom-button material-symbols-outlined small-image" />
                            <span className="button-text">Edu.Ar</span>
                        </div>
                    </button>
                    {isOpen && (
                        <div className="popup">
                            <div className="popup-content">
                                <span className={`close mt-4 p-4 ${isDancing ? 'bailar' : ''}`} onClick={togglePopup}><h5 className='text-danger'>✕</h5></span>
                                <iframe 
                                    src="https://copilotstudio.microsoft.com/environments/Default-f21334b0-75de-4641-8e59-3c0e5a7ab753/bots/crdbf_eduAr/webchat?__version__=2" 
                                    className="edubotFrame">
                                </iframe>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Footer */}
            <div className="row sticky-bottom mt-4 mb-1 text-secondary" id="contFooter">
                <div className="col-12 d-flex justify-content-center">
                    <img className="footer-logo mt-3" src={footer} alt="Ministerio footer-logo" />
                </div>
                <div className="col-12 col-md-12 d-flex justify-content-center mt-2">
                    Contacto: soporte.escuelas@me.cba.gov.ar
                </div>
                <div className="col-12 col-md-12 d-flex justify-content-center mt-2">
                    <a href="https://educacioncba.edu.ar/calidad.html" id="brandLink">Políticas de Calidad</a>
                </div>
                <div className="col-12 col-md-12 text-center mb-4">
                    © 2024 Copyright Ministerio de Educación de la Provincia de Córdoba - Subdirección de Infraestructura Tecnológica y Conectividad
                </div>
            </div>
        </div>
    );
}

export default App;   

